
.side-booster {
  height: 30em;
  width: 3.4em;
  position: absolute;
  bottom: -3em;
  z-index: 1;
  display: flex;
  flex-direction: column-reverse;

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 1.2em;
    left: 0;
    right: 0;
  }

  &::before {
    top: 3em;
    background: linear-gradient(to right, var(--cream) 50%, var(--darkCream) 50%);
  }

  &::after {
    height: 3em;
    background: linear-gradient(to right, var(--brown) 50%, var(--darkBrown) 50%);
  }

  &__nose {
    position: absolute;
    top: 1em;
    left: 50%;
    transform: translateX(-50%) scaleY(1.2) rotate(-45deg);
    width: 3.8em;
    border-radius: 80% 0.1em 55% 50% / 55% 0.1em 80% 50%;
    background: linear-gradient(135deg, var(--cream) 50%, #e8b97f 50%);

    &::before {
      content: '';
      display: block;
      padding-bottom: 100%;
    }

  }

  &__stripe {
    background: linear-gradient(to right, var(--brown) 50%, var(--darkBrown) 50%);
    height: 0.8em;
    display: block;
    position: absolute;
    left: 0;
    right: 0;

    &--1 {
      top: 4.5em;
    }

    &--2 {
      top: 11.5em;
    }

    &--3 {
      top: 14em;
    }

  }

  &__engine {
    left: 0;
    right: 0;

    &::before {
      content: '';
      display: block;
      height: 0;
      width: 2.6em;
      border-bottom: 0.5em solid var(--engineOrange);
      border-left: 0.2em solid transparent; 
      border-right: 0.2em solid transparent;
      margin: 0 auto;
    }

    &::after {
      content: '';
      display: block;
      height: 0;
      width: 3.8em;
      border-bottom: 0.7em solid var(--engineYellow);
      border-left: 0.3em solid transparent; 
      border-right: 0.3em solid transparent;
      margin: 0 0 0 -0.2em;
    }

  }

  &__trail {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: var(--lightTrail);
    height: 5em;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 3em;
      border-style: solid;
      border-color: transparent transparent var(--lightTrail) transparent;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 100%;
      width: 5em;
      height: 5em;
      background: var(--lightTrail);
      border-radius: 50%;
      margin-left: -0.8em;
    }

  }

  &__trail-dark {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 16em;
      // left: 0;
      background: var(--darkTrail);
      height: 7em;
      width: 7em;
      border-radius: 50%;
    }
  }

  &--left {
    right: 100%;

    .side-booster {

      &__trail {

        &::before {
          right: -2em;
          border-width: 0 2em 18em 17em;
        }

        &::after {
          box-shadow:
            // Above outline of trail
            0 -1em 0 var(--lightTrail),

            // Left outline of trail
            -2em 1em 0 var(--lightTrail),
            -4em 4em 0 var(--lightTrail),
            -7em 5em 0 var(--lightTrail),
            -8em 8em 0 var(--lightTrail),
            -11em 9em 0 var(--lightTrail),
            -14em 11em 0 var(--lightTrail),
            -17em 12em 0 var(--lightTrail),

            // Right outline of trail
            2em 3em 0 var(--lightTrail),
            3em 6em 0 var(--lightTrail),
            3.5em 8em 0 var(--lightTrail),
            4em 11em 0 var(--lightTrail)
        }

      }

      &__trail-dark {

        &::before {
          left: 0;

          box-shadow:
            -5em 1em 0 var(--darkTrail),
            -8em 2em 0 var(--darkTrail);
        }

      }

    }

  }

  &--right {
    left: 100%;

    .side-booster {

      &__trail {

        &::before {
          left: -2em;
          border-width: 0 17em 18em 2em;
        }
  
        &::after {
          box-shadow:
            // Above outline of trail
            0 -1em 0 var(--lightTrail),
  
            // Right outline of trail
            2em 1em 0 var(--lightTrail),
            4em 4em 0 var(--lightTrail),
            7em 5em 0 var(--lightTrail),
            8em 8em 0 var(--lightTrail),
            11em 9em 0 var(--lightTrail),
            14em 11em 0 var(--lightTrail),
            17em 12em 0 var(--lightTrail),
  
            // Left outline of trail
            -2em 3em 0 var(--lightTrail),
            -3em 6em 0 var(--lightTrail),
            -3.5em 8em 0 var(--lightTrail),
            -4em 11em 0 var(--lightTrail)
        }
  
      }

      &__trail-dark {
        
        &::before {
          right: 2em;
          box-shadow:
            4em 1em 0 var(--darkTrail),
            4em -3em 0 var(--darkTrail),
            8em 1em 0 var(--darkTrail),
            12em 2em 0 var(--darkTrail);
        }

      }

    }


  }
  
}
