html {
  --brown: #522b13;
  --darkBrown: #4b2007;

  --cream: #f4d5a4;
  --darkCream: #e8b97f;

  --lightOrange: #e89d38;
  --darkLightOrange: #bf702d;

  --darkOrange: #c54620;
  --darkDarkOrange: #a53518;

  --engineYellow: #efb23d;
  --engineOrange: #e68431;

  --lightTrail: #f7e9b9;
  --darkTrail: #f3de8d;

  --starColour: #f4ae45;

  --cloudColour: #282521;

  --satelliteLight: #544c3f;
  --satelliteDark: #3e372c;
  --satelliteAccent: #2d2722;
  --satelliteLightAccent: #7c7160;
}

*, *::before, *::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  min-height: 100vh;
  margin: 0;
  font-size: 62.5%;
  padding: 2em;
  background: #141414;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.scene {
  background: #1c1611;
  overflow: hidden;
  position: relative;
  width: 80em;
  height: 60em;
  margin: auto;
}
