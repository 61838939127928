.star {
  position: absolute;
  clip-path: polygon(50% 0%, 60% 40%, 100% 50%, 60% 60%, 50% 100%, 40% 60%, 0% 50%, 40% 40%);
  width: 1.4em;
  height: 1.4em;
  background: var(--starColour);

  &--1 {
    left: 4em;
    top: 14em;
  }

  &--2 {
    left: 12em;
    top: 18em;
  }

  &--3 {
    left: 18em;
    top: 11em;
  }

  &--4 {
    left: 26em;
    top: 13em;
  }

  &--5 {
    top: 4em;
    left: 22em;
  }

  &--6 {
    top: 6em;
    left: 32em;
  }

  &--7 {
    top: 2em;
    left: 55em;
  }

  &--8 {
    top: 4em;
    left: 62em;
  }

  &--9 {
    top: 12em;
    left: 66em;
  }

  &--10 {
    left: 58em;
    top: 14em;
  }

  &--11 {
    top: 28em;
    left: 60em;
  }

  &--12 {
    left: 44em;
    top: 0.5em;
  }

}