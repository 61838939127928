.satellite {
  position: absolute;
  top: 20em;
  right: 1.6em;
  display: flex;
  align-items: center;

  &__middle {
    width: 6.4em;
    height: 6.4em;
    border-radius: 50%;
    background: linear-gradient(to right, var(--satelliteLight) 50%, var(--satelliteDark) 50%);
    box-shadow: 0 0.3em 0 var(--satelliteLightAccent);
    margin: 0 1em;
    position: relative;

    &::before {
      content: '';
      display: block;
      width: 4.6em;
      height: 4.6em;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border: 0.7em solid var(--satelliteAccent);
    }

    &::after {
      content: '';
      display: block;
      width: 0.4em;
      height: 1em;
      background: var(--satelliteLight);
      margin: 0 auto;
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
    }

    &__panel-connector {
      display: block;
      position: relative;

      &::before,
      &::after {
        content: '';
        display: block;
        height: 0.4em;
        width: 1.4em;
        background: var(--satelliteLight);
        transform-origin: right center;
        position: absolute;
        right: 0;
        top: 0;
      }

      &::before {
        transform: rotate(-40deg);
      }

      &::after {
        transform: rotate(40deg);
      }

      &--1 {
        position: absolute;
        top: 50%;
        right: 100%;
        transform: translateY(-50%);
      }

      &--2 {
        position: absolute;
        top: 50%;
        left: 100%;
        transform: translateY(-50%) scaleX(-1);
      }

    }

  }

  &__panels {
    height: 3.6em;
    width: 6.6em;
    background: #564e41;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr 1fr 1em;
    position: relative;
    box-shadow: 0 0.3em 0 var(--satelliteLightAccent);
    z-index: 1;

    &::before,
    &::after {
      content: '';
      display: block;
      background: var(--satelliteAccent);
      position: absolute;
    }

    &::before {
      height: 0.2em;
      width: 100%;
      bottom: 0.9em;
      box-shadow: 0 -1.3em 0 var(--satelliteAccent);
    }

    &::after {
      width: 0.2em;
      height: 100%;
      left: 2.1em;
      box-shadow: 2.2em 0 0 var(--satelliteAccent);
    }

    &--1 {

      .satellite__panel {

        &--1 {
          grid-column: 3;
        }

        &--2 {
          grid-column: 2;
        }

      }

    }

    &--2 {

      .satellite__panel {

        &--1 {
          grid-column: 2;
        }

        &--2 {
          grid-column: 2;
        }

        &--3 {
          grid-column: 3;
        }

        &--4 {
          grid-column: 1;
        }

      }

    }

  }

  &__panel {
    background: var(--satelliteDark);
  }

  &__dish {
    width: 2.4em;
    height: 2.4em;
    border-radius: 50%;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background: var(--satelliteLightAccent);
    margin-top: 1em;
    box-shadow: 0 -0.1em 0 #a59780;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      width: 0.5em;
      height: 0.5em;
      background: var(--satelliteAccent);
    }

    &__line {
      display: block;
      width: 0.2em;
      height: 1em;
      background: var(--satelliteAccent);
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;

      &--1 {
        top: 50%;
        transform: translateY(-100%);
      }

      &--2 {
        top: 50%;
        transform-origin: top center;
        transform: rotate(45deg);
      }

      &--3 {
        top: 50%;
        transform-origin: top center;
        transform: rotate(-45deg);
      }

    }

  }

}