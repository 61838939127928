
.space-shuttle {
  height: 26em;
  width: 6em;
  margin: 0 auto;
  position: absolute;
  bottom: -1em;
  left: 0;
  right: 0;
  z-index: 2;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-top-left-radius: 2.8em 10em;
    border-top-right-radius: 2.8em 10em;
    background: linear-gradient(to right, var(--cream) 50%, var(--darkCream) 50%);
    z-index: 1;
  }


  &__bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(to right, var(--lightOrange) 50%, var(--darkLightOrange) 50%);
    height: 5.6em;
    z-index: 1;
  }

  &__engine {
    position: absolute;
    top: 100%;
    left: 0.5em;
    height: 1em;

    &::before {
      content: '';
      display: block;
      height: 0;
      width: 1em;
      height: 0.4em;
      background: var(--engineOrange);
    }

    &::after {
      position: absolute;
      content: '';
      display: block;
      height: 0;
      width: 1.2em;
      border-bottom: 0.6em solid var(--engineYellow);
      border-left: 0.1em solid transparent; 
      border-right: 0.1em solid transparent;
      left: 50%;
      transform: translateX(-50%);
    }


    &--right {
      right: 0.5em;
      left: auto;
    }

  }

  &__tail {
    height: 8.5em;
    width: 0.8em;
    margin: 0 auto;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 1.9em; /* 1.9 to fix small gap in FF */
      left: 0;
      right: 0;
      bottom: 1.2em;
      background: linear-gradient(to right, var(--cream) 50%, var(--lightOrange) 50%);
    }

    &__top {
      position: relative;

      &::before,
      &::after {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        position: absolute;
        top: 0;
      }

      &::before {
        border-width: 0 0 2em 0.4em;
        border-color: transparent transparent var(--cream) transparent;
        left: 0;
      }

      &::after {
        border-width: 2em 0 0 0.4em;
        border-color: transparent transparent transparent var(--lightOrange);
        right: 0;
      }

    }

    &__bottom {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;

      &::before,
      &::after {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        position: absolute;
        bottom: 0;
      }

      &::before {
        border-width: 0 0.4em 1.2em 0;
        border-color: transparent var(--cream) transparent transparent;
        left: 0;
      }

      &::after {
        border-width: 1.2em 0.4em 0 0;
        border-color: var(--lightOrange) transparent  transparent transparent;
        right: 0;
      }

    }

  }

  &__wings {
    position: absolute;
    display: inline-flex;
    height: 19em;
    overflow: hidden;
    z-index: 0;
    left: 50%;
    transform: translateX(-50%);
    top: 6em;
    padding: 0 1em;
    color: var(--lightOrange);

    &__inner {
      filter: url(#goo);
      position: relative;
    }

    &__1 {
      width: 8em;
      height: 18em;
      background: currentColor;
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
    }

    &__2 {
      width: 20em;
      height: 20em;
      background: currentColor;
      border-radius: 50%;
      margin-top: 9.5em;
    }

    &--2 {
      transform: translateX(-50%) scale(0.92) translateY(0.85em);
      color: #e66f2c;

      &::before,
      &::after {
        content: '';
        display: block;
        width: 3.4em;
        height: 0.5em;
        background: #eed0a1;
        position: absolute;
        z-index: 1;
        bottom: 0.6em;
      }

      &::before {
        left: 0.9em;
      }

      &::after {
        right: 0.9em;
      }

      .space-shuttle__wings__1 {
        width: 7.5em;
      }

    }

  }


  &__line {
    position: absolute;
    left: 0;
    right: 0;
    background: linear-gradient(to right, #dfbe88 50%, #ca9d6b 50%);
    height: 0.1em;
    z-index: 1;

    &--1 {
      top: 8.5em;
    }

    &--2 {
      top: 15em;
    }

  }

  &__windows {
    position: absolute;
    top: 2em;
    left: 50%;
    z-index: 1;
    left: 0;
    right: 0;

    &::before,
    &::after {
      content: '';
      display: block;
      width: 0.5em;
      height: 0.7em;
      position: absolute;
      top: 0.2em;
      z-index: 2;
      background: var(--darkBrown);
    }

    &::before {
      left: 1.5em;
      transform: rotate(-20deg);
    }

    &::after {
      right: 1.5em;
      transform: rotate(20deg);
    }

    &__middle {
      width: 2em;
      height: 1em;
      overflow: hidden;
      position: relative;
      margin: 0 auto;

      &::before {
        content: '';
        display: block;
        width: 7em;
        height: 7em;
        border-radius: 50%;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        border: 0.7em solid var(--darkBrown);
      }

      &__line {

        &::before,
        &::after {
          content: '';
          display: block;
          width: 0.4em;
          height: 2em;
          position: absolute;
          top: 0;
        }

        &::before {
          background: var(--cream);
          transform: rotate(-20deg);
          left: 0;
        }

        &::after {
          background: var(--darkCream);
          transform: rotate(20deg);
          right: 0;
        }

      }

    }
    
  }


}